$primary-color: #51aa52;

.app {
  height: 100% !important;
}
.index {
  // 顶部导航栏
  .headerBox {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    color: white;
    height: 0.78rem;
    padding: 0 0.3rem;
    display: flex;
    .headerLeft {
      display: flex;
      align-items: center;
      padding: 0 0.5rem 0 0.5rem;
      width: 35%;
      height: 100%;
      float: left;
      cursor: pointer;
      img {
        // border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.1rem;
        border-radius: 50%;
      }
    }

    .headerRight {
      height: 0.78rem;
      flex: 1;
      box-sizing: border-box;
      display: flex;
      // border: 2px solid #51aa52;
      flex-direction: column;
      .tabBox {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
      }

      .liBox {
        color: $primary-color;
        font-weight: bold;
        height: 0.3rem;
        display: flex;
        width: 1.2rem;
        align-items: center;
        justify-content: center;
        font-size: 0.14rem;
        cursor: pointer;
        background-color: white;
      }
      .liBoxActive {
        color: white;
        border-radius: 0.2rem;

        background-color: $primary-color;
      }
      // ul {
      //   li:hover {
      //     background-color: #51aa52 !important;
      //     border-radius: 0.2rem;
      //     color: white !important;
      //   }
      // }
    }

    .ant-input {
      &::placeholder {
        font-size: 0.12rem;
      }
    }
  }
}

// 内容区域
.app > main {
  // position: fixed;
  // top: 0.78rem;
  width: 100%;
  // height: 100%;
  flex: 1;
}

// 底部区域

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
