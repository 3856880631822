// @import '../../lib/common.less';

.scope_page {
  padding: 0 0.3rem;

  .upperUl {
    width: 70%;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      position: relative;
      transition: all 0.5s;
      top: 0.2rem;
      flex: 1;
      img {
        width: 0.5rem;
      }
      .last_span {
        // display: none;
        // transition: all 0.5s;
      }
      height: 1.2rem;
    }

    li:hover {
      top: 0;
      .last_span {
        // display: inline;
        // transition: all 0.5s;
      }
    }

    li:nth-of-type(2) {
      margin: 0 0.3rem;
    }
  }
  main {
    height: 3.6rem;
    // border:2px solid red;
    display: flex;
    flex-direction: column;

    ul {
      height: 0.7rem;
      // background-color: aqua;
      display: flex;
      justify-content: center;

      li {
        height: 100%;
        width: 1.2rem;
        // border:1px solid red;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.12rem;
        padding-top: 0.05rem;
        cursor: pointer;

        span {
          display: inline-block;

          padding-bottom: 0.05rem;
          font-size: 0.14rem;
        }
      }

      li:nth-of-type(2) {
        // background-color: red;
        margin: 0 0.4rem;
      }

      li:nth-of-type(3) {
        margin-right: 0.4rem;
      }
    }

    .content {
      background-color: #f7f7f7;
      width: 80%;
      margin: 0 auto;
      flex: 1;
      margin-bottom: 0.5rem;
      display: flex;

      & > article {
        width: 60%;
        height: 100%;
        font-size: 0.13rem;
        color: rgba(0, 0, 0, 0.6);

        // background-color: red;
        // vertical-align: middle;
        display: flex;
        padding: 0 0.5rem;
        justify-content: center;
        align-items: center;
        div {
          text-align: left;
          line-height: 0.25rem;
        }
      }

      & > img {
        // flex: 2;
        width: 40%;
        height: 100%;
      }
    }
  }
}
